import { useState, useEffect } from 'react';
import Image from 'next/image';
import axios from 'axios';


const CustomImage = ({ src, alt, width, height, priority = true,...props }) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [error, setError] = useState(false);

  const handleError = () => {
    setError(true);
  };

  return error ? (
    <div
      className="w-full h-0 pb-[56.25%] bg-gray-200"
      style={{
        backgroundImage: `url("data:image/svg+xml;base64,${toBase64(shimmer(width, height))}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  ) : (
    // next Image tag updated - 29/08/2024
    <Image
      src={imgSrc}
      alt={alt}
      onError={handleError}
      priority={true}
      sizes="100vw"
      layout="responsive"
      // loading="lazy" // Enable lazy loading
      placeholder="blur" // Default blur effect
      blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(width, height))}`} // Blurred placeholder
      width={width}
      height={height}
      {...props}
    />
  );
};

// Function to create a shimmer effect for the placeholder
const shimmer = (w, h) => `
  <svg width="${w}" height="${h}" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <defs>
      <linearGradient id="g">
        <stop stop-color="#333" offset="20%" />
        <stop stop-color="#222" offset="50%" />
        <stop stop-color="#333" offset="70%" />
      </linearGradient>
    </defs>
    <rect width="${w}" height="${h}" fill="#333" />
    <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
    <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
  </svg>`;

const toBase64 = (str) => typeof window === 'undefined'
  ? Buffer.from(str).toString('base64')
  : window.btoa(str);

export default CustomImage;
